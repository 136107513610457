import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Deadlifts 12-12-12`}</p>
    <p>{`KB SDHP’s 6-6-6-6`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Tumilson”`}</strong></p>
    <p>{`8 Rounds for time of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`11 Burpee DB Deadlifts (60’s/40’s)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids today at 9:30 at Louisville East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      